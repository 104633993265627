<template>
  <div>
    <div v-if="store.userData.products.length > 0" class="basket">
      <div class="basket__products">
        <div class="basket__product" v-for="(product, index) in store.userData.products" :key="product.id">
          <ShirtPreviewBox :productData=product></ShirtPreviewBox>
          <span v-on:click="removeProductFromIndex(index)" class="basket__erase"><img alt="Clear Icon" src="@/assets/images/icons/clear_icon.png">Entfernen</span>  
        </div>
      </div>
      <div class="basket__summary">
        <div class="basket__summary_item">
          <span class="basket__summary_item_left">Zwischensumme:</span><span class="basket__summary_item_right">{{getCurrentPrice.toFixed(2)}}€</span>
        </div>
        <div class="basket__summary_item">
          <span class="basket__summary_item_left">Versand:</span><span class="basket__summary_item_right">{{calculatePorto.toFixed(2)}}€</span>
        </div>
      </div>
      <div class="basket__summary">
        <div class="basket__summary_item">
          <span class="basket__summary_item_left"><span class="basket__summary_item_big">Gesamtsumme</span> inkl. MwST:</span><span class="basket__summary_item_right basket__summary_item_big">{{(getCurrentPrice+calculatePorto).toFixed(2)}}€</span>
        </div>
      </div>
      <div class="product__button button shadow-box" v-on:click="gotoBasketForm()">
        Zur Kasse
      </div>
      

      <div class="product__infos">
          <router-link class="product__back_link link_secondary link_secondary_mid_plain" to="/shop">
            <span>Weiter Shoppen</span>
        </router-link>
      </div>
    </div>

    <div v-if="store.userData.products.length == 0" class="basket">
      <h3>Du hast noch keine Shirts in deinem Warenkorb</h3>
      <div class="product__button button shadow-box" v-on:click="gotoShop()">
        Zum Shop
      </div>
    </div>
  </div>

  
</template>

<script>
import store from '@/store/store.js';
import ShirtPreviewBox from "@/components/ShirtPreviewBox.vue";

export default {
  name: 'Basket',
  components: {
    ShirtPreviewBox
  },
  data() {
    return {
      store
    };
  },
  computed: {
    getCurrentPrice: function () {
      var price = 0;
      for(var i = 0; i < this.store.userData.products.length; i++){
        price += this.store.userData.products[i].preis;
      }
      return price
    },

    calculatePorto: function() {
      var portoPrice = 2.30;
      var sweatersCount = 0;
      var shirtCount = 0;

       for(var i = 0; i < this.store.userData.products.length; i++){

        console.log(this.store.userData.products[i].kategorie.toLowerCase());

        if(this.store.userData.products[i].kategorie.toLowerCase().includes("shirt")){
          shirtCount++;
        }
        if(this.store.userData.products[i].kategorie.toLowerCase().includes("sweats")){
          sweatersCount++;
        }
        if(this.store.userData.products[i].kategorie.toLowerCase().includes("specials") ||
          this.store.userData.products[i].kategorie.toLowerCase().includes("bags") 
        ){
          sweatersCount++;
        }
      }

      if(shirtCount == 1 && sweatersCount == 0){
        portoPrice = parseFloat(this.store.porto1Shirt);
      }
      if(shirtCount == 2 && sweatersCount == 0){
        portoPrice = parseFloat(this.store.porto2Shirts);
      }
      if(shirtCount > 2 && shirtCount < 5 && sweatersCount == 0){
        portoPrice = parseFloat(this.store.portoShirtGrosser2Kleiner5);
      }
      if(sweatersCount == 1 && shirtCount == 0){
        portoPrice = parseFloat(this.store.portoSweaters1);
      }
      if(sweatersCount >= 1 && shirtCount >= 1){
        portoPrice = parseFloat(this.store.portoSweaterGrosser1OderShirtsGrosser4);
      }
      if(shirtCount > 4 || sweatersCount > 1){
        portoPrice = parseFloat(this.store.portoSweaterGrosser1OderShirtsGrosser4);
      }

      return portoPrice;
    }
  },
  methods: {
   gotoBasketForm(){
    this.store.userData.porto = this.calculatePorto;
    this.$router.push('userForm');
   },
   gotoShop(){
     this.$router.push('shop');
   },
   removeProductFromIndex(index){
     this.store.userData.products.splice(index, 1);
   }
  }
}
</script>

<style lang="scss">
  .basket{
    color: $color-text;
    @media only screen and (min-width: 612px){ 
     max-width: 750px;
      margin: auto;
      padding-top: 230px;
    }

    .shirt-box {
      cursor: auto;
    }

    .shirt-box__name{
      width: 43%;
    }
    padding-top: 130px;
    margin-bottom: 50px;
    &__product{
      position: relative;
      margin-bottom: 25px;
    }
    &__products{
      .shirt-box__inner{
        img{
          width: 100px;
          display: block;
        }
        .shirt-box__name{
          position: absolute;
          left: 140px;
          top: 0px;
        }
        .shirt-box__price{
          position: absolute;
          left: 140px;
          top: 30px;
        }
        .shirt-box__size{
          top: 0;
        }
      }
    }
    &__erase{
      position: absolute;
      left: 140px;
      top: 75px;
      cursor: pointer;
      img{
        width: 14px;
        position: absolute;
        left: 0;
        top: 5px;
      }
      font-size: 20px;
      font-family: 'Bebas Neue', sans-serif;    
      text-transform: uppercase;
      font-weight: normal;
      text-align: left;
      display: block;
      color: $color-primary;
      padding-left: 20px;
    }
    .product__button{
      min-width: 220px;
      margin: auto;
    }
    h3{
      text-align: center;
    }
    .link_secondary_mid_plain{
      background: none;
      position: relative;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      padding: 0;
      margin-top: 10px;
    }
  }
</style>